const enhanceWithClickOutside = require('react-click-outside');
const createReactClass = require('create-react-class');
const React = require('react');

const WithClickOutside = createReactClass({
  handleClickOutside(ce) {
    ce.stopPropagation();
    if (this.props.onClickOutside) this.props.onClickOutside(ce);
  },

  render() {
    return <>{this.props.children}</>;
  }
});

module.exports = enhanceWithClickOutside(WithClickOutside);
