import React from 'react'
import { Link } from 'gatsby'
import base from './base.css'
import Navigation from './navigation'
import Footer from './shared/Footer';

const Template = ({ location, minimal, children, isPage = true }) =>
  {
    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    const footerLinks = [
      {label: "Privacy Policy", attrs: {to: "/legal/privacy"}},
      {label: "Terms", attrs: {to: "/legal/terms"}}
    ];

    return (
      <div className="flex flex-col h-screen justify-between">
        <div>
          <Navigation key="nav" minimal={minimal} location={location} />
          <main className={isPage ? "mb-auto mx-auto max-w-6xl mt-4" : ""}>{children}</main>
        </div>
        <Footer LinkComponent={Link} links={footerLinks} />
      </div>);
  };

export default Template
