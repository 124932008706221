const React = require('react');
const useState = React.useState;
const WithClickOutside = require('./WithClickOutside');

module.exports = ({button, children}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{position: 'relative'}}>
      <div onClick={() => setIsOpen(!isOpen)}>{button}</div>
      {isOpen && <WithClickOutside onClickOutside={() => setIsOpen(false)}>
                   <div onClick={() => setIsOpen(false)}>
                     {children}
                   </div>
                 </WithClickOutside>}
    </div>
  );
}
