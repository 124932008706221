const React = require('react');

module.exports = ({LinkComponent, links}) => 
  <footer className="bg-gray-800" aria-labelledby="footerHeading">
    <div className="max-w-6xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex items-center justify-between text-sm">
      <nav className="flex flex-wrap justify-center" aria-label="Footer">
        {links.map(({label, attrs},i) => 
           LinkComponent 
             ? <LinkComponent className="text-gray-400 hover:text-gray-200 mr-4" {...attrs}>
                 {label}
               </LinkComponent>
             : <a key={label} className="text-gray-400 hover:text-gray-200 mr-4" {...attrs}>{label}</a>)}
             <a className="text-gray-400 hover:text-gray-200" href="mailto:support@forkplan.com">Support</a>
      </nav>
      <p className="text-gray-400 md:mt-0 md:order-1">
        © {new Date().getFullYear()}
      </p>
    </div>
  </footer>;
