import React, { useState } from 'react'
import { Link } from 'gatsby'
import NavigationBar from './shared/NavigationBar';
import env from './env';

const homeUrl = user =>
  ((env.ENVIRONMENT == "development" || env.NODE_ENV == "development") 
     ? (!user ? "http://localhost:8000/" : "http://localhost:4000/home/")
     : (!user ? "/" : "/home/"));

const menuItemsByRole = {
  null: [
    // { url: '/plans/', label: 'Explore Plans' }
  ],
  'Admin': [
    { url: '/plans/', label: 'Plans' },
    { url: '/recipes/', label: 'Recipes' },
    { url: '/ingredients/', label: 'Ingredients' },
    { url: '/users/', label: 'Users' },
    { url: '/categories/', label: 'Categories' },
    { url: '/admin/', label: 'Admin' },
    { url: '/usda-importer/', label: 'USDA Importer' },
    { url: '/test/', label: 'Test' }
  ],
  'Full': [
    { url: '/my-food/plans', label: 'My Plans' },
    { url: '/my-food/recipes', label: 'My Recipes' }
  ]
};

const imageUrl = 
  ({ FileID, Name, fileID, name }, {w,h}) => 
    `https://${env().IMGIX_SUBDOMAIN}.imgix.net/${FileID || fileID}/${Name || name}?w=${w*2}&h=${h*2}&fit=crop`;

const appUrl = url => (process.env.NODE_ENV == 'development' ? "http://localhost:4000" : "") + url;

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: null, isLoaded: false };
  }

  componentDidMount() {
    if (!this.props.minimal) {
      fetch("/api/ssg/user", { method: 'POST' })
        .then(response => {
          try {
            return response.json();
          } catch(err) {
            console.error("ERROR: ", err);
            return null;
          }
        }).then(user => {
          this.setState({user: user, isLoaded: true});
        });
    }
  }

  render() {
    const {user, isLoaded} = this.state;
    const {minimal} = this.props;
    const menuItems = menuItemsByRole[user && user.Role];

    return <NavigationBar homeUrl={homeUrl(user)}
                          menuItems={menuItems.map(x => ({url: appUrl(x.url), label: x.label}))}
                          mode={isLoaded ? (user ? "LoggedIn" : "LoggedOut") : ""}
                          user={{imageRef: user && user.Image && {Value: user.Image}, 
                                 displayName: user && user.DisplayName, 
                                 userName: user && user.UserName}}
                          logInButton={{url: appUrl("/account/login")}}
                          signUpButton={{url: appUrl("/account/join")}}
                          viewProfileButton={{url: appUrl("/account/profile")}}
                          logOutButton={{url: appUrl("/account/logout")}}
                          imageUrl={imageUrl}
                          minimal={minimal} />;
  }
}

export default Navigation;
