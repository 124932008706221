const React = require('react');
const useState = React.useState;
const PopoverButton = require('./PopoverButton');

const DropdownMenu = ({options}) =>
  <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50">
    <div className="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
      {options.map(({label, onClick, url}) =>
        <a href={url || "#"} onClick={onClick && (ce => { onClick(); ce.preventDefault(); })} key={label}
           className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:text-white hover:bg-blue-600 focus:outline-none 
                      focus:bg-gray-100 transition duration-150 ease-in-out" 
           role="menuitem">{label}</a>)}
    </div>
  </div>;

const AccountButton = ({imageRef, name, imageUrl}) =>
  <button className="flex text-sm border-1 border-transparent rounded-full focus:outline-none 
                     transition duration-150 ease-in-out items-center hover:bg-gray-900
                     py-2 pl-2 pr-2 sm:pr-3" 
          aria-label="User menu" aria-haspopup="true">
    <img className="h-8 w-8 rounded-full sm:mr-2" 
         src={imageRef ? imageUrl(imageRef.Value, {w:28, h:28}) : '/content/img/account.svg'} />
    <div className="hidden sm:block text-gray-50">{name}</div>
  </button>;

const UserMenu = ({viewProfileButton, logOutButton, user, imageUrl}) => 
  <div className={`absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0`}>
    <div className="ml-3 relative">
      <PopoverButton button={<AccountButton imageRef={user.imageRef}
                                            name={user.displayName || user.userName}
                                            imageUrl={imageUrl} />}>
        <DropdownMenu options={[{label: 'Edit Profile', onClick: viewProfileButton.onClick, url: viewProfileButton.url},
                                {label: 'Log Out', onClick: logOutButton.onClick, url: logOutButton.url}]} />
      </PopoverButton>
    </div>
  </div>;

const SignUpButton = ({url, onClick}) =>
  <a href={url} onClick={onClick && (ce => { onClick(); ce.preventDefault(); })}
     className="px-6 py-1.5 rounded text-gray-50 bg-blue-700 hover:bg-blue-800 font-bold">
    Join
  </a>;

const LogInButton = ({url, onClick}) =>
  <a href={url} onClick={onClick && (ce => { onClick(); ce.preventDefault(); })}
     className="px-6 py-1.5 rounded text-gray-50 border border-solid border-white sm:mr-4"
     style={{fontSize: 14}}>
    Sign In
  </a>;

module.exports = ({homeUrl, onHomeClick, menuItems, mode, logInButton, signUpButton, viewProfileButton, logOutButton, user, imageUrl, minimal}) => {
  const [state, setState] = useState({mobileMenuOpen: false});

  return (
    <nav className="bg-gray-800">
      <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-50 
                               hover:bg-gray-700 hover:outline-none transition duration-150 ease-in-out" 
                    aria-label="Main menu" aria-expanded="false">
              {/* Icon when menu is closed. */}
              <svg className={`${state.mobileMenuOpen ? "hidden" : "block"} h-6 w-6`} 
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                   onClick={() => {setState({mobileMenuOpen: true})}}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>

              {/* Icon when menu is open. */}
              <svg className={`${state.mobileMenuOpen ? "block" : "hidden"} h-6 w-6`} 
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                   onClick={() => {setState({mobileMenuOpen: false})}}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="flex-1 flex items-center justify-center sm:justify-start">
            <div className="flex-shrink-0">
              {!minimal
                ? <a href={homeUrl} onClick={onHomeClick && (ce => { onHomeClick(); ce.preventDefault(); })}>
                    <img src="/content/img/logo.png" className="sm:mr-1 mt-0 p-0 h-5" />
                  </a>
                : <img src="/content/img/logo.png" className="sm:mr-1 mt-0 p-0 h-5" />}
            </div>

            <div className="hidden sm:block sm:ml-2">
              <div className="flex">
                {!minimal
                 && menuItems.map(({url, onClick, label, isSelected},i) => 
                                    <a href={url} key={url}
                                        onClick={onClick && (ce => {onClick(); ce.preventDefault(); })}
                                        className={`px-3 py-2 rounded-md text-sm leading-5 transition duration-150 ease-in-out flex-shrink-0
                                                    font-medium
                                                    ${isSelected ? "text-gray-50 bg-gray-900 text-bold" 
                                                                 : "text-gray-300 hover:text-gray-50 hover:bg-gray-700"}
                                                    ${i > 0 ? "ml-1" : ""}`}>
                                        {label}
                                    </a>)}
              </div>
            </div>
          </div>

          <div className="hidden sm:block">
            {mode == "LoggedOut" && 
              <div className="flex">
                <LogInButton url={logInButton.url} onClick={logInButton.onClick}  />
                <SignUpButton url={signUpButton.url} onClick={signUpButton.onClick} />
              </div>}
          </div>

          <div className="block sm:hidden absolute right-0">
            {mode == "LoggedOut" &&
              <SignUpButton url={signUpButton.url}
                            onClick={signUpButton.onClick 
                                     && (() => { signUpButton.onClick(); setState({mobileMenuOpen: false}) })} />}
          </div>

          {mode == "LoggedIn" 
               && <UserMenu viewProfileButton={viewProfileButton} 
                            logOutButton={logOutButton}
                            user={user}
                            imageUrl={imageUrl} />}
        </div>
      </div>

      <div className={`${state.mobileMenuOpen ? "block" : "hidden"} sm:hidden`}>
        <div className="px-2 pt-2 pb-3">
          {menuItems.map(({url, onClick, label, isSelected},i) => 
            <a href={url} key={url}
               onClick={onClick && (ce => {onClick(); setState({mobileMenuOpen: false}); ce.preventDefault(); })}
               className={`block px-3 py-2 rounded-md text-base font-medium hover:outline-none hover:text-gray-50 
                           hover:bg-gray-700 transition  duration-150 ease-in-out
                           ${isSelected ? "text-gray-50 bg-gray-900" 
                                        : "text-gray-300 hover:text-gray-50 hover:bg-gray-700"}
                           ${i > 0 ? "mt-1" : ""}`}>
              {label}
            </a>)}

          <div className="mt-2 flex justify-center">
            {mode == "LoggedOut" 
             && <LogInButton url={logInButton.url}
                             onClick={logInButton.onClick && (() => { logInButton.onClick(); setState({mobileMenuOpen: false}) })} />}
          </div>
        </div>
      </div>
    </nav>);
};
