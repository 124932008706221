module.exports = () => {
  if (window.location.href.includes('localhost'))
    return {
      IMGIX_SUBDOMAIN: 'forkplan-dev'
    };
  if (window.location.href.includes('testing.forkplan.com'))
    return {
      IMGIX_SUBDOMAIN: 'forkplan-testing'
    };
  if (window.location.href.includes('staging.forkplan.com'))
    return {
      IMGIX_SUBDOMAIN: 'forkplan-staging'
    };
  if (window.location.href.includes('www.forkplan.com'))
    return {
      IMGIX_SUBDOMAIN: 'forkplan-production'
    };
};
